// 基础工序
export const BASE_PROCESS = [
    {
        id: 1, name: '平面图像创作-手绘',
        increaseTime: time => Math.max(10, time * 0.8), decreaseTime: time => Math.min(10, time * 0.3)
    },
    {
        id: 2, name: '平面图像创作-拼接缝合',
        increaseTime: time => Math.max(4, time * 0.5), decreaseTime: time => Math.min(4, time * 0.2)
    },
    {
        id: 3, name: '平面图像创作-小元素（icon等）',
        increaseTime: time => Math.max(4, time * 0.2), decreaseTime: time => Math.min(4, time * 0.1)
    },
    {
        id: 4, name: '3D建模创作',
        increaseTime: time => Math.max(20, time * 1.5), decreaseTime: time => Math.min(4, time * 0.3)
    },
    {
        id: 5, name: '字体设计',
        increaseTime: time => Math.max(20, time * 1.5), decreaseTime: time => Math.min(4, time * 0.3)
    },
    {
        id: 6, name: 'PS修图',
        increaseTime: time => Math.max(4, time * 0.3), decreaseTime: time => Math.min(4, time * 0.1)
    },
    {
        id: 7, name: '排版',
        increaseTime: time => Math.max(4, time * 0.4), decreaseTime: time => Math.min(4, time * 0.2)
    },
    {
        id: 8, name: '色彩调配',
        increaseTime: time => Math.max(4, time * 0.4), decreaseTime: time => Math.min(4, time * 0.2)
    },
    {
        id: 9, name: '字体搜选',
        increaseTime: time => Math.max(2, time * 0.2), decreaseTime: time => Math.min(2, time * 0.1)
    },
    {
        id: 10, name: '图片搜选',
        increaseTime: time => Math.max(2, time * 0.2), decreaseTime: time => Math.min(2, time * 0.1)
    },
    {
        id: 11, name: '图表设计',
        increaseTime: time => Math.max(8, time * 0.5), decreaseTime: time => Math.min(8, time * 0.2)
    },
    {
        id: 12, name: '印刷品设计',
        increaseTime: time => Math.max(10, time * 0.5), decreaseTime: time => Math.min(10, time * 0.2)
    },
    {
        id: 13, name: '交互设计',
        increaseTime: time => Math.max(10, time * 0.5), decreaseTime: time => Math.min(10, time * 0.2)
    },
    {
        id: 14, name: '布局与结构设计',
        increaseTime: time => Math.max(10, time * 0.5), decreaseTime: time => Math.min(10, time * 0.2)
    },
    {
        id: 15, name: '初始样例创作',
        increaseTime: time => Math.max(4, time * 0.8), decreaseTime: time => Math.min(4, time * 0.3)
    },
    {
        id: 16, name: '翻译',
        increaseTime: time => Math.max(8, time * 0.8), decreaseTime: time => time * 0
    },
    {
        id: 17, name: '撰写文章-切入点',
        increaseTime: time => Math.max(2, time * 0.2), decreaseTime: time => Math.min(2, time * 0.1)
    },
    {
        id: 18, name: '撰写文章-提纲',
        increaseTime: time => Math.max(4, time * 0.4), decreaseTime: time => Math.min(4, time * 0.2)
    },
    {
        id: 19, name: '撰写文章-细节文字',
        increaseTime: time => Math.max(4, time * 0.8), decreaseTime: time => Math.min(4, time * 0.3)
    },
    {
        id: 20, name: '「业务-设计」调平',
        increaseTime: time => Math.max(8, time * 0.5), decreaseTime: time => Math.min(8, time * 0.2)
    },
    {
        id: 21, name: '「初始样例创作（高级）',
        increaseTime: time => Math.max(6, time * 0.8), decreaseTime: time => Math.min(6, time * 0.3)
    },
];

// 工作类型
export const WORK_TYPES = [
    { id: 1, name: '海报', baseWorkTime: 4, processIds: [2, 6, 7, 8, 9, 10, 15] },
    { id: 2, name: '传单', baseWorkTime: 4, processIds: [2, 6, 7, 8, 9, 10, 12, 15] },
    { id: 3, name: '朋友圈长图', baseWorkTime: 16, processIds: [2, 6, 7, 8, 9, 10, 14, 15, 20] },
    { id: 4, name: '电商详情页', baseWorkTime: 32, processIds: [2, 6, 7, 8, 9, 10, 13,14, 15, 20] },
    { id: 5, name: '易拉宝', baseWorkTime: 4, processIds: [2, 6, 7, 8, 9, 10, 12, 15] },
    { id: 6, name: 'PPT（页）', baseWorkTime: 5, processIds: [2, 6, 7, 8, 9, 10, 14, 15, 20] },
    { id: 7, name: '头图/Banner', baseWorkTime: 8, processIds: [2, 6, 7, 8, 9, 10, 13, 15] },
    { id: 8, name: '插画/插图', baseWorkTime: 10, processIds: [1,2,8,15] },
    { id: 9, name: '3D建模渲染', baseWorkTime: 20, processIds: [4, 8] },
    { id: 10, name: '封面', baseWorkTime: 10, processIds: [2, 6, 7, 8, 9, 10,12, 15] },
    { id: 11, name: '图片后期处理', baseWorkTime: 3, processIds: [6, 8] },
    { id: 12, name: 'LOGO', baseWorkTime: 30, processIds: [3, 5,7, 8, 9, 15] },
    { id: 13, name: '画册（页）', baseWorkTime: 5, processIds: [2, 6, 7, 8, 9, 10, 12, 14,15, 20] },
    { id: 14, name: '背景板', baseWorkTime: 12, processIds: [2, 6, 7, 8, 9, 10, 15] },
    { id: 15, name: '信息图表设计（个）', baseWorkTime: 8, processIds: [8, 9, 11, 15] },
    { id: 16, name: '企业名片、工牌、纸杯、文具等（项）', baseWorkTime: 10, processIds: [3, 7, 8, 9, 12, 15] },
    { id: 17, name: '明信片', baseWorkTime: 4, processIds: [6, 7, 8, 9, 10, 12, 15] },
    { id: 18, name: '邀请函', baseWorkTime: 4, processIds: [2,3, 6, 7, 8, 9, 10, 12, 15] },
    { id: 19, name: 'T恤等印花类周边品', baseWorkTime: 30, processIds: [1,2, 5, 7, 8, 12,15,20] },
    { id: 20, name: '产品目录（页）', baseWorkTime: 10, processIds: [3, 5,6,7, 8, 9, 12, 14, 15, 20] },
    { id: 21, name: '说明书（页）', baseWorkTime: 10, processIds: [3, 6, 7, 8, 9, 12, 14, 15] },
    { id: 22, name: '社交媒体文章（千字）', baseWorkTime: 4, processIds: [7, 10,17,18,19] },
    { id: 23, name: '主持词（千字）', baseWorkTime: 12, processIds: [ 17, 18, 19] },
    { id: 24, name: '简单短视频脚本（分钟）', baseWorkTime: 5, processIds: [17,18,19] },
    { id: 25, name: '翻译（千字）', baseWorkTime: 8, processIds: [16] }
    // 后续按规则补充
];

// 已有基础对工序的影响
export const BASE_CONDITIONS = {
    design: {
        name: '设计基础',
        children: [
            { id: 1, name: '模糊（只有喜欢的参照作品）', hasProcessIds: [], addProcessIds:[]},
            { id: 2, name: '明确规范（已有企业VI规范）', hasProcessIds: [5, 9], addProcessIds: []},
            { id: 3, name: '清晰样例（已有同类型的先例作品）', hasProcessIds: [5, 9, 15],addProcessIds: [] },
            { id: 4, name: '清晰样例+明确规范（已整理出该类型的模板规范）', hasProcessIds: [5, 9, 15], addProcessIds: [] },
        ]
    },
    material: {
        name: '素材基础',
        children: [
            { id: 1, name: '空白（什么也没有，或者有的都不能用）', hasProcessIds: [],addProcessIds: []  },
            { id: 2, name: '有素材但需要补充（搜索或新创作或魔改）', hasProcessIds: [],addProcessIds: []  },
            { id: 3, name: '素材充足但需要再加工（修图等）', hasProcessIds: [10],addProcessIds: []  },
            { id: 4, name: '清晰样例+已有完整的最终版素材（挑选合适的即可）', hasProcessIds: [6, 10],addProcessIds: []  },
        ]
    },
    text: {
        name: '文字基础',
        children: [
            { id: 1, name: '模糊（有选题+喜欢的样例文章）', hasProcessIds: [],addProcessIds: []  },
            { id: 2, name: '有方向（选题+样例+立意切入点）', hasProcessIds: [17],addProcessIds: []  },
            { id: 3, name: '清晰明确（选题+样例+立意切入点+提纲结构）', hasProcessIds: [17, 18],addProcessIds: []  },
            { id: 4, name: '完整（已有细节文字，但不是定稿，要再调）', hasProcessIds: [17, 18],addProcessIds: []  },
            { id: 5, name: '清晰样例+定稿（已有细节文字，而且非常确定无需再改）', hasProcessIds: [17, 18, 19],addProcessIds: []  },
        ]
    }
};


export const DESIGN_LEVEL = [
    { name: '普通', value: 1 },
    { name: '进阶', value: 2 },
    { name: '高级', value: 4 },
    { name: '大师', value: 8 },
];


export const EXAMPLE_LEVEL = [
    { name: '普通', value: 1 },
    { name: '进阶', value: 2 },
    { name: '高阶', value: 3 },
];