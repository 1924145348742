<template lang="pug">
    article.page-wrapper
        h1.page-title 叮咚PRO工时计算器
        el-form.form(ref="form", :model="form", label-width="200px", labelPosition="right",)
            el-form-item(label="工作类型：")
                el-select(v-model="form.type" placeholder="请选择工作类型")
                    el-option(:label="work.name", :key="work.id", :value="work.id", v-for="work in workTypes")
                template(v-if="form.type")
                    .tag-group.mt10
                        el-button(type="text") 基础工序：
                        el-tag.mr10(size="small", v-for="process in baseProcess", :key="process.id") {{process.name}}
                    .tag-group
                        el-button(type="text") 基础工时：
                        el-tag.mr10(size="small") {{baseWorkTime}}小时
            el-form-item(label="企业每工时价格：")
                el-input(v-model="form.price", type="number", placeholder="请填写企业每工时价格", style="width: 500px")
                     template(slot="append") 元/小时
            h2.second-title 请选择该需求已有基础条件
            el-form-item(label="已有设计基础")
                el-select(v-model="form.design" placeholder="请选择已有设计基础", style="width: 400px")
                    el-option(:label="work.name", :key="key", :value="work.id", v-for="(work, key) in workConditions.design.children")
            el-form-item(label="已有素材基础")
                el-select(v-model="form.material" placeholder="请选择已有素材基础", style="width: 400px")
                    el-option(:label="work.name", :key="key", :value="work.id", v-for="(work, key) in workConditions.material.children")
            el-form-item(label="已有文字基础")
                el-select(v-model="form.text" placeholder="请选择已有文字基础", style="width: 400px")
                    el-option(:label="work.name", :key="key", :value="work.id", v-for="(work, key) in workConditions.text.children")
                template(v-if="form.design && form.material && form.text")
                        .tag-group.mt10
                            el-button(type="text") 减去的工序：
                            el-tag.mr10(size="small",  effect="plain", type="danger", v-for="process in decreaseProcess", :key="process.id", v-if="process") {{process.name}}
                        .tag-group.mt10
                            el-button(type="text") 增加的工序：
                            el-tag.mr10(size="small",  effect="plain", type="safe", v-for="process in addProcess", :key="process.id", v-if="process") {{process.name}}
            h2.second-title 请根据用户特别要求，增减工序
            el-dialog(title='工序列表' :visible.sync='dialogVisible' width='30%')
                el-select(v-model="dialogAddItem" placeholder="请选择工作类型")
                    el-option(:label="work.name", :key="work.id", :value="work.id", v-for="work in workProcess")
                span.dialog-footer(slot='footer')
                    el-button(@click='dialogVisible = false') 取 消
                    el-button(type="primary", @click="dialogConfirm") 确认添加
            .el-transfer
                .el-transfer-panel
                    p.el-transfer-panel__header 要增加的工序
                    .el-transfer-panel__body
                        el-checkbox-group(v-model="workAddList")
                            el-checkbox(:label="item.id", v-for="item in workAddListArr", :value="item.id") {{item.name}}
                        .bottom
                            el-button(icon="el-icon-plus", type="primary", size="mini", @click="openDialog('add')")
                            el-button(icon="el-icon-minus", :disabled="!workAddList.length", type="primary", size="mini", @click="removeAddList")
                .el-transfer-panel
                    p.el-transfer-panel__header 要减少的工序
                    .el-transfer-panel__body
                        el-checkbox-group(v-model="workDecreaseList")
                            el-checkbox(:label="item.id", v-for="item in workDecreaseListArr", :value="item.id") {{item.name}}
                        .bottom
                            el-button(icon="el-icon-plus", type="primary", size="mini", @click="openDialog('decrease')")
                            el-button(icon="el-icon-minus", :disabled="!workDecreaseList.length", type="primary", size="mini", @click="removeDecreasList")
            h2.second-title.strong 计算结果
            el-form-item(label="该需求实际需要的工序：")
                template(v-if="actualProcess.length")
                    .tag-group
                        el-tag.mr10(size="small", v-for="process in actualProcess", :key="process.id") {{process.name}}
            el-form-item(label="与基础工序相比，额外增加/减少工序：", label-width="280px")
                template(v-if="extraProcess.add.length || extraProcess.remove.length")
                    .tag-group
                        el-button(type="text") 额外增加工序：
                        el-tag.mr10(size="small", v-for="process in extraProcess.add", :key="process.id") {{process.name}}
                    .tag-group
                        el-button(type="text") 额外减少工序：
                        el-tag.mr10(size="small", v-for="process in extraProcess.remove", :key="process.id") {{process.name}}
            el-form-item(label="该需求额外增加/减少工时：")
                template(v-if="extraProcess.addTime || extraProcess.removeTime")
                    .tag-group
                        el-button(type="text") 额外增加工时：
                        el-tag.mr10(size="small") {{extraProcess.addTime}}小时
                    .tag-group
                        el-button(type="text") 额外减少工时：
                        el-tag.mr10(size="small") {{extraProcess.removeTime}}小时
            el-form-item(label="该需求实际需要工时：")
                template
                    .tag-group
                        el-tag.mr10(size="small") {{actualWorkTime}}小时
            el-form-item(label="用户选择的偏好样例水平：")
                el-select(v-model="form.exampleLevel" placeholder="请选择")
                    el-option(:label="work.name", :key="work.value", :value="work.value", v-for="work in workExampleLevel")
            el-form-item(label="选择用户设计师水平：")
                el-select(v-model="form.level" placeholder="请选择")
                    el-option(:label="work.name", :key="work.value", :disabled="work.disabled" :value="work.value", v-for="work in workDesignLevel")
            el-form-item(label="最终所需工时：")
                template
                    .tag-group
                        el-tag.mr10(size="small") {{totalWorkTime}}小时
            el-form-item(label="折算价格：")
                template
                    .tag-group
                        el-tag.mr10(size="small") {{price}}元
            el-form-item(label="市场平均价格：")
                template
                    .tag-group
                        el-tag.mr10(size="small") {{avgPrice}}元
            el-form-item(label="节省比例：")
                template
                    .tag-group
                        el-tag.mr10(size="small") {{saveRatio}}%
</template>

<script>
import { WORK_TYPES, BASE_PROCESS, BASE_CONDITIONS, EXAMPLE_LEVEL, DESIGN_LEVEL } from './common/constants';

export default {
    name: 'App',
    data() {
        return {
            workAddList: [],
            workAddListArr: [],
            workDecreaseList: [],
            workDecreaseListArr: [],
            workExampleLevel: EXAMPLE_LEVEL,
            workDesignLevel: DESIGN_LEVEL,
            workTypes: WORK_TYPES,
            workProcess: BASE_PROCESS,
            workConditions: BASE_CONDITIONS,

            addOrDecrease: '',
            dialogVisible: false,
            dialogAddItem: '',
            form: {
                type: '',
                price : '',
                design: '',
                material: '',
                text: '',
                level: '',
            }
        };
    },
    watch: {
        'form.exampleLevel': function(v) {
            this.workDesignLevel.forEach(item => {
                if (v === 2 && item.value === 1) {
                    item.disabled = true;
                } else if (v === 3 && item.value <= 2) {
                    item.disabled = true;
                } else {
                    item.disabled = false;
                }
            });
        }
    },
    computed: {
        // 基础工时
        baseWorkTime() {
            const type = this.form.type;
            const { baseWorkTime } = this.workTypes.filter(work => work.id === type)[0] || {};
            return baseWorkTime;
        },
        // 基本工序
        baseProcess() {
            const type = this.form.type;
            const { processIds = [] } = this.workTypes.filter(work => work.id === type)[0] || {};
            const vals = processIds.map(id => (this.workProcess.filter(work => work.id === id)[0] || {}));
            return vals;
        },
        // 可减去工时
        decreaseProcess() {
            const design = this.workConditions.design.children.filter(item => item.id === this.form.design)[0] || { hasProcessIds: []};
            const material = this.workConditions.material.children.filter(item => item.id === this.form.material)[0] || { hasProcessIds: []};
            const text = this.workConditions.text.children.filter(item => item.id === this.form.text)[0] || { hasProcessIds: []};
            const arr = [...new Set(design.hasProcessIds.concat(material.hasProcessIds).concat(text.hasProcessIds))];
            const newArr = this.workProcess.map(work => {
                if (arr.indexOf(work.id) !== -1) {
                    return work;
                }
            });

            return newArr;
        },

        //可增加的工序
        addProcess() {
            const design = this.workConditions.design.children.filter(item => item.id === this.form.design)[0] || { addProcessIds: []};
            const material = this.workConditions.material.children.filter(item => item.id === this.form.material)[0] || { addProcessIds: []};
            const text = this.workConditions.text.children.filter(item => item.id === this.form.text)[0] || { addProcessIds: []};
            const arr = [...new Set(design.addProcessIds.concat(material.addProcessIds).concat(text.addProcessIds))];
            const newArr = this.workProcess.map(work => {
                if (arr.indexOf(work.id) !== -1) {
                    return work;
                }
            });
            return newArr;
        },

        // 实际需要工序
        actualProcess() {
            const base = this.getIds(this.baseProcess);
            const decrease = this.getIds(this.decreaseProcess);
            const addtion = this.getIds(this.addProcess);
            const addList = this.getIds(this.workAddListArr);
            const decreaseList = this.getIds(this.workDecreaseListArr);
            const add = [...new Set(base.concat(addList))];
            const remove = [...new Set(decrease.concat(decreaseList))];
            remove.forEach(id => {
                const index = add.indexOf(id);
                if (index >= 0) {
                    add.splice(index, 1);
                }
            });
            const arr = add.map(id => {
                const item = this.workProcess.filter(word => word.id === id)[0];
                return item ? item : null;
            });

            addtion.map(id => {
                const item = this.workProcess.filter(word => word.id === id)[0];
                if(item){
                    arr.push(item);
                }
            });

            return arr;
        },
        // 实际工时
        actualWorkTime() {
            const second = ((this.baseWorkTime * 3600) + (this.extraProcess.addTime * 3600) - (this.extraProcess.removeTime * 3600));
            const hour = second / 60 / 60;
            return hour || 0;
        },
        // 额外增加减少工序，工时
        extraProcess() {
            const add = [], remove = [];
            this.baseProcess.forEach(pro => {
                const item = this.actualProcess.filter(p => p.id === pro.id)[0];
                if (!item) {
                    remove.push(pro);
                }
            });
            this.actualProcess.forEach(pro => {
                const item = this.baseProcess.filter(p => p.id === pro.id)[0];
                if (!item) {
                    add.push(pro);
                }
            });
            let addTime = 0, removeTime = 0;
            add.forEach(item => {
                addTime += item.increaseTime(this.baseWorkTime);
            });
            remove.forEach(item => {
                removeTime += item.decreaseTime(this.baseWorkTime);
            });
            return {
                add,
                addTime,
                remove,
                removeTime,
            };
        },
        // 最终所需工时
        totalWorkTime() {
            const s = (this.baseWorkTime * 3600) + (this.extraProcess.addTime * 3600);
            const second = s / 60 / 60 * this.form.level;
            return second || 0;
        },
        // 折算价格
        price() {
            return this.totalWorkTime * this.form.price;
        },
        // 平均价格
        avgPrice() {
            const max = 25;
            const min = 10;
            return (this.price * (1+(Math.floor(Math.random()*(max - min + 1) + min) / 100))).toFixed(2);
        },
        // 节省比例
        saveRatio() {
            return ((((this.avgPrice / this.price)) - 1) * 100).toFixed(2) || 0;
        }
    },
    methods: {
        getIds(arr) {
            return arr.map(item => item ? item.id : null);
        },
        dialogConfirm() {
            const item = this.workProcess.filter(work => work.id === this.dialogAddItem)[0];
            if (this.addOrDecrease === 'add') {
                if (!this.workAddListArr.filter(work => work.id === item.id)[0]) {
                    this.workAddListArr.push(item);
                }
            } else {
                if (!this.workDecreaseListArr.filter(work => work.id === item.id)[0]) {
                    this.workDecreaseListArr.push(item);
                }
            }
            this.dialogAddItem = '';
            this.dialogVisible = false;
        },
        openDialog(str) {
            this.addOrDecrease = str;
            this.dialogVisible = true;
        },
        removeAddList() {
            const arr = this.workAddListArr.map(work => work.id);
            this.workAddList.forEach(id => {
                const index = arr.indexOf(id);
                if (index >= 0) {
                    this.workAddListArr.splice(index, 1);
                }
            });
            this.workAddList = [];
        },
        removeDecreasList() {
            const arr = this.workDecreaseListArr.map(work => work.id);
            this.workDecreaseList.forEach(id => {
                const index = arr.indexOf(id);
                if (index >= 0) {
                    this.workDecreaseListArr.splice(index, 1);
                }
            });
            this.workDecreaseList = [];
        }
    },
    mounted(){
    }
};
</script>

<style lang="sass">
    @import "./common/style/global.scss"
    body
        font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif
        background-color: #F7F7F7
    .mt10
        margin-top: 10px
    .mt5
        margin-top: 5px
    .mr10
        margin-right: 10px
    .mb10
        margin-bottom: 10px
    .page-wrapper
        width: 1200px
        margin: 0 auto 100px
    .form
        padding: 0 50px
    .second-title
        font-size: 14px
        font-weight: normal
        margin-left: 40px
        padding-bottom: 5px
        margin: 50px 0 30px
        border-bottom: 1px solid #dedede
        &.strong
            font-weight: bold
    .page-title
        padding: 30px 0 15px
        font-size: 20px
        border-bottom: 1px solid #dedede
        margin-bottom: 50px
    .el-transfer-panel
        margin-right: 80px
        width: 250px
        border: 1px solid #ccc
        .el-checkbox
            display: block
            margin-bottom: 10px
        .el-transfer-panel__body
            position: relative
            padding: 10px 15px
            .bottom
                display: flex
                justify-content: space-between
                border-top: 1px solid #dedede
                width: 100%
                padding: 10px 15px
                position: absolute
                left: 0
                bottom: 0
</style>